import Crm from './Crm'
export default class Insurance {
  id!: number
  member_number!: Crm
  identification!: string
  policy_number!: string
  module!: string
  coin_type!: string
  premium_amount!: number
  policy_type!: number
  policy_state!: number
  date_start!: Date | null
  date_next_payment!: Date | null
  period_type!: Date | null
  representative_data!: string
  observation!: string
  inputMemberNumber!: string
}
